import React from "react";
import ResidenceAllocation from "../Residence-Allocation";
import { useAppContext } from "../../utils/contextProvider/AppContext";
import { getAxiosInstance, getConfig } from "../../utils/ApiService";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// import Auth from "../Auth";
import { PrivateRoute, PublicRoute } from "../../utils/helper/routeFunctions";
import routes from "../../utils/routes.json";
import KenLoader from "../../components/KenLoader";
import CurrentBatch from "../Residence-Allocation/Components/Current-Batch";
import ResidenceAllocationHistory from "../Residence-Allocation/Components/Residence-Allocation-History";

export default function MainLayout() {
  const {
    dispatch,
    state: { config },
  } = useAppContext();

  React.useEffect(() => {
    getConfig()
      .then((res) => {
        dispatch({ type: "updateConfig", value: res[0]?.config });
        getAxiosInstance(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      {!config ? (
        <KenLoader />
      ) : (
        <BrowserRouter>
          <Switch>           
            <Route
              path={`/${routes.residenceAllocation}`}
              children={
                <PrivateRoute>
                  <ResidenceAllocation />
                </PrivateRoute>
              }
            />
            <Route
              path={`/${routes.currentBatch}`}
              children={
                <PrivateRoute>
                  <CurrentBatch />
                </PrivateRoute>
              }
            />
            <Route
              path={`/${routes.residenceAllocationHistory}`}
              children={
                <PrivateRoute>
                  <ResidenceAllocationHistory  />
                </PrivateRoute>
              }
            />
          </Switch>
        </BrowserRouter>
      )}
    </>
  );
}
