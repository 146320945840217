import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import logo from "../../../../assets/logo.png";
import { useHistory } from "react-router-dom";
import routes from "../../../../utils/routes.json";
import { getCurrentBatch } from "../../../../utils/ApiService";
import "./styles.css";
import KenLoader from "../../../../components/KenLoader";

const CurrentBatch = () => {
  const history = useHistory();
  const [currentBatch, setCurrentBatch] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleNavigate = () => {
    history.push(`/${routes.residenceAllocation}`);
  };

  const handleHistryNavigate = () => {
    history.push(`/${routes.residenceAllocationHistory}`, {
      isPast: true,
    });
  };

  useEffect(() => {
    getCurrentBatchInfo();
  }, []);

  const getCurrentBatchInfo = async () => {
    try {
      setLoading(true);
      const contactId = JSON.parse(localStorage.getItem("userDetails")).ContactId;
      const res = await getCurrentBatch(contactId);
      setCurrentBatch(res?.roomEligibility);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <KenLoader />}
      <Grid container justifyContent="space-between" alignItems="center" className="header-container">
        <Grid item>
          <Typography
            className="header-text"
            onClick={() => {
              localStorage.clear();
              // history.push("/");
              window.open('https://my.flame.edu.in/s/', '_self');
            }}
          >
            My Residence
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={logo}
            alt="Logo"
            className="logo-image"
            onClick={() => {
              localStorage.clear();
              history.push("/");
            }}
          />
        </Grid>
        <Grid item />
      </Grid>

      <Box className="main-container">
        <Box className="white-container">
          <Box className="content-wrapper">
            <Typography variant="h5" className="page-title">
              Residence Allocation
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Box className="card-header card-header-current">
                  <Typography className="header-text-white">Current Residence Information (2024-25)</Typography>
                </Box>
                <Box className="card-content">
                  <Typography className="card-description">Please click below to view the Current Residence Information</Typography>
                  <Button variant="contained" fullWidth className="button-current" onClick={() => handleHistryNavigate()}>
                    Click Here
                  </Button>
                </Box>
              </Grid>
              {currentBatch ? (
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="card-header card-header-upcoming">
                    <Typography className="header-text-white">Upcoming Residence Request Form (2025-26)</Typography>
                  </Box>
                  <Box className="card-content">
                    <Typography className="card-description">Please click below for the Residence Request Form Details</Typography>
                    <Button variant="contained" fullWidth className="button-upcoming" onClick={() => handleNavigate()}>
                      Click Here
                    </Button>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CurrentBatch;
