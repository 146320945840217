import { Box, Grid, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import KenLoader from "../../../../components/KenLoader";
import KenGrid from "../../../../global_components/KenGrid";
import { getResidenceHistory } from "../../../../utils/ApiService";
import TransactionsHistory from "./Transaction-History";
import logo from "../../../../assets/logo.png";
import routes from "../../../../utils/routes.json";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    color: "#222222",
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight: "22px",
    wordWrap: "break-word",
    textAlign: "left",
    marginBottom: "20px",
  },
  box: {
    boxShadow: "none",
    borderRadius: 8,
    padding: "20px",
    minHeight: "72vh",
  },
  closeICO: {
    float: "right",
    fontSize: 24,
    cursor: "pointer",
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "left",
    lineHeight: "16px",
    fontSize: "12px",
  },
  closeButtonDiv: {
    float: "right",
  },
  boxContainer: {
    display: "flex",
    gap: 20,
    marginTop: theme.spacing(2),
  },
  accommodationBox: {
    border: "0.50px #08466f solid",
    background: "#fff",
    padding: 8,
    borderRadius: "8px",
    // width: "380px",
  },
  accommodationType: {
    color: "#08466F",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22.5px",
    wordWrap: "break-word",
  },
}));

const ResidenceAllocationHistory = (props) => {
  const { isGeneralAllotmentBefore = true } = props;
  const classes = useStyles();
  const CONTACT_ID = JSON.parse(localStorage.getItem("userDetails")).ContactId;
  const { t } = useTranslation();
  const columns = [
    {
      Header: "Room Type",
      accessor: "RoomType",
      disableGlobalFilter: false,
    },
    {
      Header: "Room Number",
      accessor: "RoomNumber",
      disableGlobalFilter: false,
    },
    {
      Header: "Status",
      accessor: "Status",
      disableGlobalFilter: false,
    },
    {
      Header: "Block",
      accessor: "Block",
      disableGlobalFilter: false,
    },
  ];
  const [data, setData] = useState([]);
  const [allocationDetails, setAllocationDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const history = useHistory();
  const { state } = useLocation();

  const valueCheck = (value) => {
    return value ? value : "-";
  };

  const isPastWindow = state ? state?.isPast : false;

  useEffect(() => {
    setLoading(true);
    getResidenceHistory(CONTACT_ID, isPastWindow ? "aBh5i00000005mDCAQ" : "aBhJ3000000KyksKAC")
      .then((res) => {
        console.log("res enterenece page", res);
        if (res.success) {
          let transactionDetails = [];
          res?.transactionDetail?.records?.map((item, index) => {
            let detail = {
              feeAmount: valueCheck(item.Received_Amount__c),
              mode: valueCheck(item?.Payment_Transaction__r?.Mode_Of_Payment__c),
              recordId: valueCheck(item?.Payment_Transaction__c),
              webhookStatus: valueCheck(item?.Payment_Transaction__r?.Webhook_Status__c),
              reconciliationStatus: valueCheck(item?.Payment_Transaction__r?.Reconciled__c),
              transactionDate: valueCheck(item?.Payment_Transaction__r?.CreatedDate),
            };
            transactionDetails.push(detail);
          });
          const allocations = {};
          const data = res?.data?.map((items) => {
            allocations["Residence Allocation"] = {
              "Residence Type": items?.Room__r?.Room_Category__r?.Name__c ? `${items?.Room__r?.Room_Category__r?.Name__c} (Provisional Allocation)` : "-",
              Description: items?.Room__r?.Room_Category__r?.Description__c ? items?.Room__r?.Room_Category__r?.Description__c : "-",
              Room: !isPastWindow ? "-" : valueCheck(!isGeneralAllotmentBefore ? null : items?.Room__r?.Room_Number__c) !== "-" ? valueCheck(!isGeneralAllotmentBefore ? null : items?.Room__r?.Room_Number__c) : "To be announced at time of Confirmed Allocation",
              Block: !isPastWindow ? "-" : valueCheck(!isGeneralAllotmentBefore ? null : items?.Room__r?.Block__c) !== "-" ? valueCheck(!isGeneralAllotmentBefore ? null : items?.Room__r?.Block__c) : "To be announced at time of Confirmed Allocation",
              // Zone: valueCheck(!isGeneralAllotmentBefore ? null : items?.Room__r?.Residence_Zone__c),
            };
            if (isGeneralAllotmentBefore) {
              allocations["Residence Counsellor"] = {
                Name: valueCheck(items?.Room__r?.Floor__r?.Residence_Counsellor__r?.Name),
                "Phone Number": valueCheck(items?.Room__r?.Floor__r?.Residence_Counsellor__r?.Phone),
                Email: valueCheck(items?.Room__r?.Floor__r?.Residence_Counsellor__r?.Email),
              };
            }
            return {
              ...items,
              AcademicYear: "-",
              RoomType: valueCheck(items?.Room__r?.Room_Category__r?.Name__c),
              RoomNumber: valueCheck(items?.Room__r?.Room_Number__c),
              Status: valueCheck(items?.Payment_Status__c),
              Block: valueCheck(items?.Room__r?.Block__c),
              // Zone: valueCheck(items?.Room__r?.Residence_Zone__c),
            };
          });
          const roomMateDetails = [];
          if (isGeneralAllotmentBefore) {
            res?.roommateDetail?.map((items, index) => {
              if (isPastWindow) {
                allocations["Roommate Details"] = {
                  Name: valueCheck(items?.Tenant__r?.Name),
                  "Phone Number": valueCheck(items?.Tenant__r?.Phone),
                  Email: valueCheck(items?.Tenant__r?.Email),
                };
              }
              roomMateDetails.push({
                Name: valueCheck(items?.Tenant__r?.Name),
                "Phone Number": valueCheck(items?.Tenant__r?.Phone),
                Email: valueCheck(items?.Tenant__r?.Email),
              });
            });
          }
          setTransactionHistory(transactionDetails);
          setData(data);
          setAllocationDetails(allocations);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          marginBottom: "-4px",
        }}
        alignItems="center"
      >
        <Grid item>
          <Typography
            style={{
              marginLeft: "20px",
              color: "#08466F",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(`/${routes.currentBatch}`);
            }}
          >
            HOME
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={logo}
            height={"80px"}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.clear();
              history.push("/");
            }}
          />
        </Grid>
        {!isMobileScreen && <Grid item />}
      </Grid>

      {loading && <KenLoader />}
      <Grid
        container
        xs={12}
        style={{
          gap: 20,
          marginTop: "25px",
          marginBottom: "auto",
          padding: "0 24px",
        }}
      >
        {allocationDetails && (
          <Grid item container>
            <Grid item xs={12}>
              <Typography variant="p" className={classes.header}>
                Provisional Allocation
              </Typography>
            </Grid>
            <Typography variant="p" component={"p"} className={classes.description}>
              Based on the preferences sent in the Residence Request form, your Provisional Allocation is as follows:
            </Typography>
            <Grid item container xs={12} spacing={2}>
              {Object.entries(allocationDetails).map(([key, value]) => {
                return (
                  <Grid item xs={12} md={4}>
                    <Box className={classes.accommodationBox} key={key}>
                      <Typography variant="body1" className={classes.accommodationType}>
                        {key}
                      </Typography>
                      <Box>
                        {Object.entries(value).map(([subKey, subValue], index) => {
                          return <Typography key={index} style={{ fontSize: "12px", margin: "8px 0px" }} component={"body"}>{`${subKey} : ${subValue}`}</Typography>;
                        })}
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        {data.length > 0 && isGeneralAllotmentBefore && isPastWindow && (
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.header}>
              {t(`headings:Residence_Allocation_History`)}
            </Typography>
            <KenGrid
              columns={columns}
              data={data}
              pagination={{ disabled: true }}
              toolbarDisabled={true}
              headerStyle={{ border: "1px solid #D2E1E9" }}
              gridProps={{
                getHeaderGroupProps: (column) => ({
                  style: { border: "1px solid #D2E1E9" },
                }),
                getHeaderProps: (column) => ({
                  style: {
                    background: "#E0E0E0",
                    color: "#000",
                    textAlign: "center",
                    fontSize: 14,
                  },
                }),
                getColumnProps: (column) => ({
                  style: {
                    background: "#fff",
                    textAlign: "center",
                    color: "#000",
                  },
                }),
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.header}>
            {t(`headings:Transaction_History`)}
          </Typography>
          {transactionHistory && <TransactionsHistory data={transactionHistory} />}
        </Grid>
      </Grid>
    </>
  );
};

export default ResidenceAllocationHistory;
