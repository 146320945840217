import { Box, Button, Grid, Paper, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoIosClose } from "react-icons/io";
import DocumentUpload from "../../../../components/KenDocumentUpload";
import KenLoader from "../../../../components/KenLoader";
import KenDialog from "../../../../global_components/KenDialogBox";
import KenInputField from "../../../../global_components/KenInputField";
import KenTextLabel from "../../../../global_components/KenTextLabel";
import { getDocumentUrl, postRoomRequest } from "../../../../utils/ApiService";
import { REGEX } from "../../../../utils/constants";
import Accommodation from "./Accommodation/Accommodation";

const useStyles = makeStyles((theme) => ({
  header: {
    color: "#222222",
    fontSize: "20px",
    fontFamily: "Lato",
    fontWeight: 600,
    lineHeight: "22.5px",
    wordWrap: "break-word",
    textAlign: "left",
  },
  header2: {
    color: "#222222",
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 600,
    lineHeight: "22.5px",
    wordWrap: "break-word",
    textAlign: "left",
  },
  box: {
    boxShadow: "none",
    borderRadius: 8,
    padding: "25px",
    minHeight: "85vh",
    position: "relative",
  },
  closeICO: {
    float: "right",
    fontSize: 30,
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    lineHeight: "22.5px",
    fontSize: "16px",
  },
  closeButtonDiv: {
    justifyContent: "right",
    marginTop: "12px",
  },
  boxContainer: {
    marginTop: theme.spacing(3),
  },
  switchText: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22.5px",
  },
  switchText2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22.5px",
  },
  switchCase: {
    width: "100%",
    marginTop: "35px",
  },
  medicalFont: {
    display: "flex",
    gap: 30,
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  dialog: {
    "& .MuiDialog-paperFullWidth": {
      padding: "16px",
      minHeight: "40vh",
      borderRadius: "20px",
      boxShadow: "none",
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiButton-containedPrimary": {
      textTransform: "capitalize !important",
    },
    "& .MuiButton-outlinedPrimary": {
      textTransform: "capitalize !important",
      border: "none",
    },
  },
  dialogHeader: {
    color: "#222",
    fontSize: "20px",
    fontWeight: "600",
  },
  preferenceHeader: {
    color: "#222",
    fontSize: "16px",
    fontWeight: "500",
  },
  preferenceBody: {
    color: "#08466F",
    fontSize: "20px",
    marginBottom: 8,
    fontWeight: 600,
  },
  dialogBody: {
    marginTop: 40,
    fontSize: "16px",
  },
  statusImage: {
    width: "60px",
  },
  preferenceBtn: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  mark: {
    "&::marker": {
      fontSize: "16px",
    },
  },
}));

const RequestApplication = (props) => {
  const { handleClose, currentData, termData, data, preferenceOptions, handleChangePreference, resetPreference, studentRequestWindowId, roomRequest, myTeam } = props;

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const CONTACT_ID = JSON.parse(localStorage.getItem("userDetails")).ContactId;
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectRoomate, setSelectRoomate] = useState(false);
  const [specialNeeds, setSpecialNeeds] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preferenceOrder, setPreferenceOrder] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const { register, handleSubmit: formSubmit, control, setValue, getValues } = useFormContext();
  const [payload, setPayload] = useState();

  console.log('dataaaa',currentData)

  const toggleRoomate = () => {
    setSelectRoomate(!selectRoomate);
  };
  const toggleSpecialNeeds = () => {
    setSpecialNeeds(!specialNeeds);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleSubmit = () => {
    let payloadCopy = { ...payload };
    payloadCopy.preference1 = data?.find((el) => el.preference === "Preference 1")?.Id;
    payloadCopy.preference2 = data?.find((el) => el.preference === "Preference 2")?.Id;
    payloadCopy.preference3 = data?.find((el) => el.preference === "Preference 3")?.Id;
    setLoading(true);
    postRoomRequest(payloadCopy)
      .then((res) => {
        if (res?.success) {
          setHideButton(true);
          setTimeout(() => {
            handleClose();
          }, 10000);
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error?.response?.data?.message || "Something went wrong", {
          variant: "error",
        });
      });
  };

  const roommateGuideline = [
    "Selecting a roommate of the same sex.",
    "Selecting a roommate of same batch year",
    "The roommate selected also be making the same Residence Type preference as you.",
    "The roommate selected has also selected you as their preferred roommate.",
    "The roommate preference is as per the policy.",
  ];
  useEffect(() => {
    setValue("preference1", currentData?.Preference_1__c);
    setValue("preference2", currentData?.Preference_2__c);
    setValue("preference3", currentData?.Preference_3__c);
    setValue("studentEmail", currentData?.Preferred_Partner__r?.Email);
    setValue("description", currentData?.Special_Needs_Description__c);
    setValue("specialNeeds", currentData?.Special_Needs__c);
    console.log(currentData, "4h7ty745");
    if (currentData?.Preferred_Partner__r?.Email) {
      setSelectRoomate(true);
    }
    if (currentData?.Special_Needs__c) {
      setSpecialNeeds(true);
    }
    if (currentData?.Status__c === "Pending" || currentData?.Status__c === "Provisional Alloted") {
      setPreviewMode(true);
    }
  }, [currentData]);

  useEffect(() => {
    if (currentData === "Pending" || currentData === "Provisional Alloted") {
      setPreviewMode(true);
    }
  }, [currentData]);

  const handleFilterPreferences = () => {
    const matchedArray = [];
    preferenceOptions.forEach((data1Obj) => {
      const matchedData2Obj = data.find((data2Obj) => data2Obj.preference === data1Obj.value);
      if (matchedData2Obj) {
        matchedArray.push({
          label: data1Obj.label,
          value: data1Obj.value,
          roomName: matchedData2Obj.Name__c,
        });
      }
    });
    setPreferenceOrder(matchedArray);
  };

  const submitRequest = async (requestData) => {
    let error = false;
    data?.forEach((item) => {
      if (item.preference === "") {
        error = true;
        return;
      }
    });
    if (error === true) {
      enqueueSnackbar("Please fill all the preferences", {
        variant: "warning",
      });
    } else {
      let body = {};
      if (requestData?.attachment?.length === 0) {
        delete requestData.attachment;
      }
      body = {
        ...requestData,
        contactId: CONTACT_ID,
        status: "Pending",
        studentRequestWindowId: studentRequestWindowId,
      };
      if (requestData?.attachment && requestData?.attachment?.length !== 0) {
        const base64 = await toBase64(requestData?.attachment);
        const fileName = requestData?.attachment?.name;
        body = {
          ...body,
          attachment: {
            Name: fileName,
            base64String: base64.split(",")[1],
          },
        };
      }
      handleFilterPreferences();
      setPayload(body);
      handleDialogOpen();
    }
  };

  const handleDocumentUrl = async () => {
    const res = await getDocumentUrl(roomRequest?.Id);
    if (res?.success) {
      window.open(res?.data?.publicUrl, "_blank");
    } else {
      enqueueSnackbar(res?.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      {loading && <KenLoader />}
      <Grid container xs={12}>
        <Grid item component="form" onSubmit={formSubmit(submitRequest)} xs={12}>
          <Paper className={classes.box}>
            <Grid item xs={12}>
              <Typography variant="p" className={classes.header}>
                {t(`headings:Residence_Request_Form`)}
              </Typography>
              <IoIosClose className={classes.closeICO} onClick={handleClose} />
            </Grid>
            <Grid item xs={12} style={isMobileScreen ? { marginTop: "10px" } : null}>
              <Typography variant="p" className={classes.description}>
                All fields marked with <span style={{ color: "red" }}>*</span> are to be filled mandatorily.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "35px" }}>
              <Grid item xs={12}>
                <Typography variant="p" className={classes.header2}>
                  {t(`headings:Residence_Type`)} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p" className={classes.description}>
                  {t(`content:Select_Room`)}{" "}
                </Typography>
              </Grid>
              {data && data?.length > 0 ? (
                <Grid container xs={12}>
                  <Grid item xs={12} className={classes.boxContainer}>
                    <Grid container xs={12} lg={12} spacing={4}>
                      {data.map((items, index) => {
                        return (
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Accommodation disabled={previewMode} preferenceOptions={preferenceOptions} item={items} termData={termData} key={index} handleChangePreference={handleChangePreference} myTeam={myTeam} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Typography>No Rooms Available</Typography>
              )}
              <Grid container xs={12} style={{ marginTop: 20 }}>
                <Grid container xs={12} style={{ margin: "10px auto" }}>
                  <Grid item xs={12}>
                    <Typography variant="p" className={classes.switchText}>
                      {t(`content:Select_Roomate`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p" className={classes.description}>
                      {t(`content:Roommate_Guidelines`)}
                    </Typography>
                    <ol type="a">
                      {roommateGuideline.map((guide) => {
                        return (
                          <li className={classes.mark}>
                            <Typography variant="p" className={classes.description}>
                              {guide}
                            </Typography>
                          </li>
                        );
                      })}
                    </ol>

                    <Typography variant="p" className={classes.description}>
                      {t(`content:Roommate_Guidelines2`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.switchCase}>
                      <Controller
                        name="studentEmail"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                          console.log(error);
                          return (
                            <KenInputField
                              labelStyles={{
                                marginTop: "20px",
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#777",
                              }}
                              label={t(`labels:Roommate_email`)}
                              name="studentEmail"
                              disabled={previewMode}
                              placeholder="example@flame.edu.in"
                              optionalLabel={false}
                              value={value}
                              dropdownColor="#FFFFFF"
                              error={error?.message}
                              size="small"
                              onChange={onChange}
                              InputProps={{
                                style: {
                                  background: "#fff",
                                  border: "1px solid #E4E4E4",
                                  fontFamily: "lato",
                                  borderRadius: "8px",
                                  marginRight: "32px",
                                },
                              }}
                            />
                          );
                        }}
                        {...register("studentEmail", {
                          pattern: {
                            value: REGEX.EMAIL,
                            message: "Invalid Email ID",
                          },
                        })}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container xs={12}>
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Grid item xs={12}>
                      <Typography variant="p" className={classes.switchText}>
                        {t(`content:Special_Needs`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="p" className={classes.switchText2}>
                        {t(`content:Special_Needs_Info`)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: "8px" }}>
                    <Box className={classes.switchCase}>
                      <div>
                        <Grid container spacing={2} xs={12}>
                          <Grid item xs={12} md={4}>
                            <Controller
                              name="description"
                              control={control}
                              render={({ field: { onChange, value }, fieldState: { error } }) => {
                                return (
                                  <KenInputField
                                    labelStyles={{
                                      marginTop: "20px",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      color: "#777777",
                                      textTransform: "uppercase",
                                    }}
                                    label={`${t(`labels:Enter_Request`)}`}
                                    name="description"
                                    optionalLabel={false}
                                    disabled={previewMode}
                                    placeholder={"Enter all relevant details in relation to the student's physical / mobility challenge here"}
                                    rows={4}
                                    multiline={true}
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    error={error?.message}
                                    InputProps={{
                                      style: {
                                        background: "#fff",
                                        border: "1px solid #E4E4E4",
                                        fontSize: "14px",
                                        fontFamily: "lato",
                                        borderRadius: "8px",
                                        marginRight: "24px",
                                      },
                                    }}
                                  />
                                );
                              }}
                              {...register("description", {})}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <div className={classes.medicalFont}>
                              <KenTextLabel
                                label={t(`headings:Upload_Docs`)}
                                styles={{
                                  marginTop: "20px",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  color: "#777777",
                                  textTransform: "uppercase",
                                }}
                              />
                            </div>
                            {previewMode ? (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  textTransform: "capitalize",
                                  maxHeight: "35px",
                                  marginTop: "8px",
                                }}
                                onClick={() => handleDocumentUrl()}
                              >
                                View Document
                              </Button>
                            ) : (
                              <DocumentUpload acceptedType={".pdf, .png, .jpg, .jpeg"} disabled={previewMode} attachments={[]} width={"100%"} />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} md={12} className={classes.closeButtonDiv}>
              {previewMode ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      maxHeight: "35px",
                    }}
                    onClick={handleClose}
                  >
                    {t(`Buttons:Close`)}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="primary"
                    variant="secondary"
                    style={{
                      textTransform: "capitalize",
                      marginRight: "10px",
                      maxHeight: "35px",
                      width: 145,
                    }}
                    onClick={() => {
                      resetPreference();
                    }}
                  >
                    {t(`Buttons:Clear_Preferences`)}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      textTransform: "capitalize",
                      maxHeight: "35px",
                      width: 135,
                    }}
                  >
                    {t(`Buttons:Submit_Request`)}
                  </Button>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
        <KenDialog
          open={dialogOpen}
          dialogStyle={classes.dialog}
          handleClose={() => handleDialogClose()}
          negativeButtonClick={() => handleDialogClose()}
          positiveButtonClick={() => handleSubmit()}
          hidePositiveButton={hideButton}
          positiveButtonProps={{ style: { textTransform: "capitalize" } }}
          negativeButtonProps={{ style: { textTransform: "capitalize" } }}
          hideNegativeButton={hideButton}
          positiveButtonText="Confirm"
          negativeButtonText="Go Back"
        >
          {!hideButton ? (
            <Grid xs={12}>
              <Typography className={classes.dialogHeader}>{t(`headings:Confirm_Submission`)}</Typography>
              <Box
                xs={12}
                style={{
                  border: "1px solid #E1E2E3",
                  borderRadius: "8px",
                  padding: "16px",
                }}
              >
                <Typography className={classes.preferenceHeader}>{t(`headings:Preference 1`)}</Typography>
                <Typography className={classes.preferenceBody}>{preferenceOrder.length > 0 ? preferenceOrder[0]?.roomName : "N/A"}</Typography>
                <Typography className={classes.preferenceHeader}>{t(`headings:Preference 2`)}</Typography>
                <Typography className={classes.preferenceBody}>{preferenceOrder.length > 0 ? preferenceOrder[1]?.roomName : "N/A"}</Typography>
                <Typography className={classes.preferenceHeader}>{t(`headings:Preference 3`)}</Typography>
                <Typography className={classes.preferenceBody}>{preferenceOrder.length > 0 ? preferenceOrder[2]?.roomName : "N/A"}</Typography>
              </Box>
              <Typography className={classes.dialogBody} style={{ marginTop: 12 }}>
                {t(`messages:Please_Review`)}
              </Typography>
              <Typography className={classes.dialogBody} style={{ marginTop: 12 }}>
                {t(`messages:Please_Review2`)}
              </Typography>
            </Grid>
          ) : (
            <Grid xs={12} style={{ textAlign: "center" }}>
              <img src={"https://inazstgpfs3001.blob.core.windows.net/assets/flame/Vector.svg"} alt="Submitted" className={classes.statusImage} />
              <Typography className={classes.dialogHeader} style={{ marginTop: "20px" }}>
                {t(`messages:Request_Submitted`)}
              </Typography>
              <Typography className={classes.dialogBody} style={{ marginTop: "20px" }}>
                {t(`messages:Request_Submit_Message1`)}
              </Typography>
              {/* <Typography
                className={classes.dialogBody}
                style={{ marginTop: '20px' }}
              >
                {t(`messages:Request_Submit_Message2`)}
              </Typography> */}
            </Grid>
          )}
        </KenDialog>
      </Grid>
    </>
  );
};
export default RequestApplication;
